import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Pagination from '../components/Pagination';
import Email from '../components/Email';

import styles from './events.module.css';

const EventsPage = ({ data }) => {
  return (
    <Layout>
      <Section heading="Mount Mary events">
        <p>
          Over the past 12 months we’ve celebrated with many of you at our
          annual release dinners in Melbourne, Sydney and Brisbane as well as
          the launch of our 2017 Marli Russell wines in April this year at
          Cumulus Up in Melbourne. Below is a snapshot of some memorable
          moments from these past events.
        </p>
        <p>
          As this newsletter goes to print, we will shortly commence our annual
          release events for 2019. This year marks the 4th annual Members Table
          dinner in Melbourne and the third year for our annual interstate
          events in Sydney and Brisbane. The tradition of these events has
          quickly become a highlight for us in particular, sharing the very first
          tastings of the new release wines with you, our friends and extended Mount
          Mary family.
        </p>
      </Section>
      <Section>
        <div className={styles.imageRow}>
          <Img fluid={data.image1.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image2.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image3.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image5.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image6.childImageSharp.fluid} className={styles.image} />
        </div>
        <div className={styles.imageRow}>
          <Img fluid={data.image7.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image9.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image10.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image11.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image12.childImageSharp.fluid} className={styles.image} />
        </div>
        <div className={styles.imageRow}>
          <Img fluid={data.image13.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image15.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image16.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image17.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image18.childImageSharp.fluid} className={styles.image} />
        </div>
        <div className={styles.imageRow}>
          <Img fluid={data.image19.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image8.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image4.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image14.childImageSharp.fluid} className={styles.image} />
          <Img fluid={data.image20.childImageSharp.fluid} className={styles.image} />
        </div>
      </Section>
      <Section heading="2019 Annual release events">
        <div className={styles.eventEntry}>
          <p className={styles.eventTitle}>Melbourne Members’ Table Dinner</p>
          <p>Thursday 15th August</p>
          <p>Stokehouse, St Kilda</p>
        </div>
        <div className={styles.eventEntry}>
          <p className={styles.eventTitle}>Sydney Dinner</p>
          <p>Thursday 22nd August</p>
          <p>Quay, The Rocks</p>
        </div>
        <div className={styles.eventEntry}>
          <p className={styles.eventTitle}>Brisbane New Release Tasting Event</p>
          <p>Thursday 5th September</p>
          <p>Stokehouse Q, Southbank</p>
        </div>
        <p>
          Please email <Email /> for further information and to book tickets.
        </p>
        <p>
          We also hope to extend these events to WA and SA in 2020. Please register interest via admin@mountmary.com.au
        </p>
      </Section>
      <Pagination
        forwardLink="/visiting"
        backLink="/vintage"
      />
    </Layout>
  );
};

EventsPage.propTypes = {
  data: PropTypes.shape({
    image1: PropTypes.object,
    image2: PropTypes.object,
    image3: PropTypes.object,
    image4: PropTypes.object,
    image5: PropTypes.object,
    image6: PropTypes.object,
    image7: PropTypes.object,
    image8: PropTypes.object,
    image9: PropTypes.object,
    image10: PropTypes.object,
    image11: PropTypes.object,
    image12: PropTypes.object,
    image13: PropTypes.object,
    image14: PropTypes.object,
    image15: PropTypes.object,
    image16: PropTypes.object,
    image17: PropTypes.object,
    image18: PropTypes.object,
    image19: PropTypes.object,
    image20: PropTypes.object,
  }),
};

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "events-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "events-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "events-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "events-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image5: file(relativePath: { eq: "events-5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image6: file(relativePath: { eq: "events-6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image7: file(relativePath: { eq: "events-7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image8: file(relativePath: { eq: "events-8.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image9: file(relativePath: { eq: "events-9.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image10: file(relativePath: { eq: "events-10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image11: file(relativePath: { eq: "events-11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image12: file(relativePath: { eq: "events-12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image13: file(relativePath: { eq: "events-13.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image14: file(relativePath: { eq: "events-14.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image15: file(relativePath: { eq: "events-15.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image16: file(relativePath: { eq: "events-16.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image17: file(relativePath: { eq: "events-17.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image18: file(relativePath: { eq: "events-18.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image19: file(relativePath: { eq: "events-19.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image20: file(relativePath: { eq: "events-20.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default EventsPage;
